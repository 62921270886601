import "./src/styles/global.css"
import "./src/styles/icon-font.css"
import "./src/styles/print.css"
import "intersection-observer"
import "./src/styles/toast.css"

import WrapRoot from "./src/gatsby-api/wrap-root"

const wrapRootElement = WrapRoot

export { wrapRootElement }
